dialog {
  border: none;
  padding: 0px;
  background: none;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
}

::backdrop {
  background-color: black;
  opacity: 0.50;
}

// The following is largely copied and pasted from bootstrap's source and then
// tailored to our applicaiton.

.cow-modal {
  max-width: 800px;
  width: 100%;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  padding: 0px;
}

// Actual modal
.cow-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  border-radius: var(--bs-border-radius-lg);
  box-shadow: var(--bs-box-shadow-sm);
  overflow: scroll;
}

// Modal header
// Top section of the modal w/ title and dismiss
.cow-modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 1rem 1rem;
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);

  .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
  }
}

// Title text within header
.cow-modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.cow-modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: 1rem;
}

// Footer (for actions)
.cow-modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: 0.75rem;
  border-top: var(--bs-border-width) solid var(--bs-border-color);

  // Place margin between footer elements
  // This solution is far from ideal because of the universal selector usage,
  // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
  > * {
    margin: 0.25rem; // Todo in v6: replace with gap on parent class
  }
}
