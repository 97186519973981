// 1. Configuration and Variables
$forge-dark-bg: #1a1a1a;
$forge-light-bg: #f2f3f4;
$forge-accent: #ff5722;
$forge-accent-hover: #ff7043;

// Theme Colors
$primary: $forge-accent;
$success: $forge-accent;
$color-mode-type: media-query;

// Custom Properties
:root {
  // Shared properties
  --cow-accent: #{$forge-accent};
  --cow-accent-hover: #{$forge-accent-hover};
  --cow-bs-primary-rgb: #{red($primary)}, #{green($primary)}, #{blue($primary)};
  
  // Light mode defaults
  --cow-bg: #{$forge-light-bg};
  --bs-body-bg: #{$forge-light-bg};
  --cow-card-gradient: linear-gradient(145deg, #ffffff, #dddddd);
  --cow-card-border: #444;
  --cow-text-primary: #000;
  --cow-text-secondary: #444;
  --cow-component-bg: #fff;
  --cow-input-bg: #f8f9fa;
  --cow-input-border: #ced4da;
  --cow-shadow-color: rgba(0, 0, 0, 0.15);
  --cow-glow-color: rgba(255, 87, 34, 0.2);
}

// Dark mode overrides
@media (prefers-color-scheme: dark) {
  :root {
    --cow-bg: #{$forge-dark-bg};
    --bs-body-bg: #{$forge-dark-bg};
    --cow-card-gradient: linear-gradient(145deg, #2a2a2a, #232323);
    --cow-card-border: #444;
    --cow-text-primary: #fff;
    --cow-text-secondary: #aaa;
    --cow-component-bg: #2a2a2a;
    --cow-input-bg: #1a1a1a;
    --cow-input-border: #444;
    --cow-shadow-color: rgba(0, 0, 0, 0.3);
    --cow-glow-color: rgba(255, 87, 34, 0.2);
  }
}

// 2. Required Bootstrap imports
@import "~bootstrap/scss/bootstrap.scss";

// 3. Base Styles
body {
  background: var(--cow-bg);
  color: var(--cow-text-primary);
}

// 4. Component Styles
.card {
  background: var(--cow-card-gradient);
  border-color: var(--cow-card-border);
  color: var(--cow-text-primary);
  
  &-title {
    font-family: 'Arial Black', sans-serif;
    margin-top: 10px;
    font-size: 18px;
    position: relative;
  }


  // Card column variants
  &-columns {
    @include make-col-ready();

    @include media-breakpoint-up(xs) { @include make-col(6); }
    @include media-breakpoint-up(md) { @include make-col(4); }
    @include media-breakpoint-up(lg) { @include make-col(3); }
    @include media-breakpoint-up(xl) { @include make-col(2.4); }
    @include media-breakpoint-up(xxl) { @include make-col(2); }
  }

  &-columns-4-max {
    @include make-col-ready();

    @include media-breakpoint-up(xs) { @include make-col(6); }
    @include media-breakpoint-up(sm) { @include make-col(4); }
    @include media-breakpoint-up(md) { @include make-col(3); }
  }

  &-columns-5-max {
    @include make-col-ready();

    @include media-breakpoint-up(xs) { @include make-col(6); }
    @include media-breakpoint-up(md) { @include make-col(4); }
    @include media-breakpoint-up(lg) { @include make-col(3); }
    @include media-breakpoint-up(xl) { @include make-col(2.4); }
  }
}

// Form Controls
.form-control {
  background-color: var(--cow-input-bg);
  border-color: var(--cow-input-border);
  color: var(--cow-text-primary);
  
  &:focus {
    background-color: var(--cow-input-bg);
    border-color: var(--cow-accent);
    color: var(--cow-text-primary);
    box-shadow: 0 0 0 0.25rem rgba(var(--cow-bs-primary-rgb), 0.25);
  }
}

// Buttons
.btn-primary {
  background: linear-gradient(135deg, var(--cow-accent), var(--cow-accent-hover));
  border: none;
  box-shadow: 0 2px 4px var(--cow-shadow-color);
  
  &:hover {
    background: linear-gradient(135deg, var(--cow-accent-hover), var(--cow-accent));
    transform: translateY(-1px);
    box-shadow: 0 4px 8px var(--cow-shadow-color);
  }
  
  &:active {
    transform: translateY(1px);
  }
}

// Forge-style glowing animation
@keyframes forge-glow {
  0% { box-shadow: 0 0 5px var(--cow-glow-color); }
  50% { box-shadow: 0 0 20px var(--cow-glow-color); }
  100% { box-shadow: 0 0 5px var(--cow-glow-color); }
}

.forge-glow {
  animation: forge-glow 3s infinite;
}

// Modal styles
.modal {
  background: var(--cow-card-gradient);
  
  &-content {
    background: var(--cow-card-gradient);
    border-color: var(--cow-card-border);
  }
  
  &-header {
    border-bottom-color: var(--cow-accent);
  }
  
  &-footer {
    border-top-color: var(--cow-card-border);
  }
}

// I named these "aspect-*" because they work different than bootstrap's ratio-* classes
.aspect-1x1 {
  aspect-ratio: 1 / 1;
}

.aspect-xl-4x3 {
  @include media-breakpoint-up(xl) {
    aspect-ratio: 4 / 3;
  }
}

.aspect-md-unset {
  @include media-breakpoint-up(md) {
    aspect-ratio: unset;
  }
}

// Component Imports
@import './iconSizes.scss';
@import './icons.scss';
@import './links.scss';
@import './dragHandle.scss';
@import './creationCard.scss';
@import './hoverShadow.scss';
@import './input_validation.scss';
@import './unstyled.scss';
@import './button_text.scss';
@import './scrollBehavior.scss';
@import './smallContainer.scss';
@import './blockquote.scss';
@import './modal.scss';
